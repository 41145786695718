<template>
  <div>
    <div class="dis align_center">
      <div class="icon"></div>
      <div class="service" v-if="correct">修改基础信息月报</div>
      <div class="service" v-else>添加基础信息月报</div>
    </div>
    <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="266px" :label-position="labelPosition">
      <el-form-item label="填报人" prop="name" required>
        <el-input class="width_500" type="text" placeholder="请输入填报人姓名" v-model="ruleForm.name" autocomplete="off">
        </el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone" required>
        <el-input class="width_500" type="text" placeholder="请输入联系电话" v-model="ruleForm.phone" autocomplete="off">
        </el-input>
      </el-form-item>
      <el-form-item label="本月带动服务资源数量" prop="num" required>
        <el-input v-model.number="ruleForm.num" class="width_500" placeholder="请输入带动服务资源数量；例如：5家（次）"></el-input>
      </el-form-item>
      <el-form-item label="报送月份" required>


        <el-date-picker v-model="dataForm.time" type="month" placeholder="选择日期" value-format="yyyy-MM">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="典型案例介绍" prop="num" required>
        <div>
          <el-input type="textarea" :rows="6" class="width_500" placeholder="请输入典型案例介绍" v-model="textarea">
          </el-input>
        </div>
      </el-form-item>

      <el-button v-if="correct" type="primary" @click="corrects()"
        style="background-color: #ff6618; border-color: #ff6618">确定修改</el-button>
      <el-button v-else type="primary" @click="add()" style="background-color: #ff6618; border-color: #ff6618">确定添加
      </el-button>
      <el-button type="primary" @click="back()" style="background-color: #cfcfcf; border-color: #cfcfcf">取消</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelPosition: "left",
      value3: "",
      value2: "",
      textarea: "",
      correct: "",
      dataForm: {
        year: "",
        month: "",
        time: "",
      },
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        phone: "",
      },
    };
  },
  created() {
    if(this.$route.query.id){
      this.correct = this.$route.query.id
    }
    
    console.log(this.correct,'this.correct')
  },
  methods: {

    back() {
      this.$router.push("/information");
    },
    corrects() {
      let data = {
        token: localStorage.eleToken,
        name: this.ruleForm.name,
        mobile: this.ruleForm.phone,
        num: this.ruleForm.num,
        content: this.textarea,
        submit_time: this.dataForm.time,
        type: 1,
        id: this.correct,
      };
      console.log(data);
      this.$post("/monthly_upd", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/information");

      });
    },
    add() {
      let data = {
        token: localStorage.eleToken,
        name: this.ruleForm.name,
        mobile: this.ruleForm.phone,
        num: this.ruleForm.num,
        content: this.textarea,
        submit_time: this.dataForm.time,
        type: 1,
      };
      console.log(data);
      this.$post("/monthly_add", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/information");

      });
    },
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.width_500 {
  width: 500px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}
</style>